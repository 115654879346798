<template>
  <div class="home">
      <h1>Les jeux-vidéo</h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
    }
  },
  metaInfo(){
    return {
      title : 'Proxym4',
      meta : [
        /* TWITTER */
        { name: 'twitter:card', content: 'summary'},
        { name: 'twitter:title', content: 'Proxym4.tv'},
        { name: 'twitter:description', content: '1/4 de finaliste ZLAN, stream du mardi au jeudi de 18 à 22h'},
        { name: 'twitter:image', content: this.metaImg },
        /* FACEBOOK */
        { property: 'og:title', content: 'Proxym4.tv' },
        { property: 'og:site_name', content: 'Proxym4.tv' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: this.metaImg },
        { property: 'og:description', content: '1/4 de finaliste ZLAN, stream du mardi au jeudi de 18 à 22h'},
      ]
    }
  },
  methods : {
  },
  mounted(){
  }
}
</script>
